require("./bootstrap");
import Swiper from "swiper/bundle";
import AOS from "aos/dist/aos.js";
// import { Navigation, Pagination } from 'swiper/modules';

$("form").submit(function () {
    $(this).find(":input[type=submit]").prop("disabled", true);
});

// new Swiper(".cards-swiper", {
//     effect: "coverflow",
//     grabCursor: true,
//     centeredSlides: true,
//     slidesPerView: "auto",
//     freeMode: true,
//     coverflowEffect: {
//         rotate: 50,
//         stretch: 0,
//         depth: 150,
//         // depth: 100,
//         modifier: 1,
//         slideShadows: true,
//     },
//     autoplay: {
//         delay: 2100,
//         disableOnInteraction: false,
//     },
//     pagination: {
//         el: ".swiper-pagination",
//         clickable: true,
//     },
// });

new Swiper(".memories-swiper", {
    spaceBetween: 10,
    grabCursor: true,
    centeredSlides: true,
    freeMode: false,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        // spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
        // spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        // spaceBetween: 50,
      },
    },
    // pagination: {
    //     el: ".swiper-pagination",
    //     clickable: true,
    // },
});

// AOS Start
AOS.init({
    duration: 500,
    easing: 'ease-in-out',
});
